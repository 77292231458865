<template>
	<v-container>
		
		<v-row>
			<v-col cols="12" md="6">
				<v-card outlined class="pa-4" :loading="link_gen_loading">
					<v-card-title>
						Invitation link
					</v-card-title>
					<v-card-text >
						<v-text-field
						ref="invite_url"
						label="Uniqe invitation link"
						outlined
						dense
						readonly
						v-model="invite_url"
						></v-text-field>
						
					</v-card-text>
					<v-card-actions >
					<v-btn
						outlined
						@click="get_new_link()"
						color="teal accent-4"
						:loading="link_gen_loading"
						>
						Get new link
					</v-btn>
					<v-btn
						outlined
						@click="copy_invite_link()"
						color="teal accent-4"
						:disabled="new_code_generated"
						>
						Copy
					</v-btn>
				</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="6">
				<v-card outlined class="pa-4" :loading="stat_loading">
					<v-card-title>
						Invitation Stat
					</v-card-title>
					<v-card-text >
						<p>Used invite: {{used_invite}}</p>
						<p>Total allowed invite: {{invite_limit}}</p>
						<p>Total generated link: {{total_gen_code}}</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>

		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>
	</v-container>
</template>
<script>
import {get_invite_code,get_invite_stat}  from '@/api/user'
export default {
	name: 'Invitation',
	data(){
		return{
			new_code_generated:true,
			used_invite:0,
			invite_limit:0,
			total_gen_code:0,
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			link_gen_loading:false,
			stat_loading:false,
			invite_url:""
		}
	},
	methods:{

		get_new_link:function(){
			this.link_gen_loading=true;
			get_invite_code().then(response => {
				this.link_gen_loading=false;
				
				if (response.error==false){
					this.new_code_generated=false;
					let host = window.location.protocol + "//" + window.location.host;
					let url=host+'/invite/'+response.data.invite_code
					this.invite_url=url;
					this.used_invite=response.data.used_invite;
					this.invite_limit=response.data.user_limit;
					this.total_gen_code=response.data.total_gen_code;
					this.success_snakbar=true;
					this.snackbar_success_message="A new invite link generated";
				}else{
					// show error
					this.error_snackbar=true;
					this.snackbar_error_message=response.error_message;
				}
			})
		},
		copy_invite_link:function(){
			//let invite_url = this.$refs.invite_url.$el.querySelector('input')
			//invite_url.select()
			//document.execCommand("copy");
			navigator.clipboard.writeText(this.invite_url)
			this.success_snakbar=true;
			this.snackbar_success_message="Invite link copped to clipboard";
		},
		get_invite_stat:function(){
			get_invite_stat().then(response=>{
				console.log(response);
				if (response.error == false){
					this.used_invite=response.data.used_invite;
					this.invite_limit=response.data.user_limit;
					this.total_gen_code=response.data.total_gen_code;
				}
			});
		}
	},
	created:function() {
		this.get_invite_stat();
	}

}
</script>