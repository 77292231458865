<template>
	<div class="signup-form">
		<v-toolbar flat>
			<!-- <v-btn
				icon
				@click="$router.go(-1)"
				>
				<v-icon>mdi-arrow-left</v-icon>

			</v-btn>
 -->
			<v-btn
				icon
				href="/"
				>
				<v-icon>mdi-home-variant-outline</v-icon>
				
			</v-btn>


			
		</v-toolbar>
		<div v-if="!valid_invite_code">
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4 lg4>
						<v-card class="pa-4" outlined>
							<v-card-text>
								<div class="layout column align-center">
									<img
										src="@/assets/next-level-logo.svg"
										alt="Next Level Login"
										width="150"
										height="150"
									/>
									<h1 class="flex my-8 font-weight-light">
										{{check_invite_message}}
									</h1>
								</div>
								
							</v-card-text>
							<div class="px-4 font-weight-light body-2">
								<v-btn
									:loading="check_inviate_loading"
									large
									block
									href="/"
									color="teal white--text"
									
									>Home</v-btn
								>
							</div>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
		<div v-if="!verify_object_id && valid_invite_code">
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4 lg4>
						<v-card class="pa-4" outlined>
							<v-card-text>
								<div class="layout column align-center">
									<img
										src="@/assets/next-level-logo.svg"
										alt="Next Level Login"
										width="150"
										height="150"
									/>
									<h1 class="flex my-4 font-weight-light">
										Creat New Account
									</h1>
									<p>
										NextLevel Bahrain in the final phase of development, you can create an account today and get early access to Bahrain market data.
									</p>
									<p>
										Note: The service is limited to users with invitation.
									</p>
								</div>
								<v-form
								ref="signup_form"
								lazy-validation
								>
									<v-text-field
										append-icon="mdi-account"
										outlined
										name="name"
										label="Name"
										type="text"
										v-model="name"
										:error="error"
										:rules="name_rules"

									/>
									<div>
										<v-row>
											<v-col cols="4">
												<v-select
														:items="country_phone_code"
														label="Country Code"
														v-model="selected_country_phone_code"
														outlined
													></v-select>
											</v-col>
											<v-col cols="8">
												<v-text-field
													append-icon="mdi-phone"
													outlined
													name="phone"
													label="Phone"
													type="text"
													v-model="phone"
													:error="error"
													:rules="phone_rules"/>
										</v-col>
										
									</v-row>

									</div>
									<v-text-field
										append-icon="mdi-email"
										outlined
										name="login"
										label="Email"
										type="text"
										v-model="email"
										:error="error"
										:rules="email_rules"
										
									/>
									<v-text-field
										outlined
										:type="hidePassword ? 'password' : 'text'"
										:append-icon="
											hidePassword
												? 'mdi-eye-off-outline'
												: 'mdi-eye-outline'
										"
										name="password"
										label="Password"
										id="password"
										:rules="password_rules"
										v-model="password"
										:error="error"
										@click:append="hidePassword = !hidePassword"

									/>

									

								</v-form>
								<p>
									Phone number will used for the next validation step.
								</p>
								<p >
									By clicking sign up you agree to our terms of use and privacy policy.
								</p>
							</v-card-text>
							<div class="px-4">
								
								<v-btn
									large
									block

									color="teal white--text"
									:loading="signup_loading"
									@click="signup"
									>Sign up</v-btn
								>
							</div>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</div>

		<div v-if="verify_object_id">
			<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4 lg4>
					<v-card class="pa-4" outlined>
						<v-card-text>
							<div class="layout column align-center">
								<img
									src="@/assets/next-level-logo.svg"
									alt="Next Level Login"
									width="150"
									height="150"
								/>
								<h1 class="flex my-4 font-weight-light">
									Varify SMS Code
								</h1>
							</div>
							<v-form
							ref="sms_verification_form"
							lazy-validation
							>
								<v-text-field
									append-icon="mdi-message-text-outline"
									outlined
									name="sms_code"
									label="SMS Code"
									type="text"
									v-model="sms_code"
									
								/>

							</v-form>
						</v-card-text>
						<div class="px-4 font-weight-light">
							<div class="d-flex mb-6">
								<div>
									<v-btn
										large
									
										color="blue-grey white--text"

										@click="cancel_validation"
										>Cancel</v-btn
									>
								</div>
								<div class="ml-auto">
									<v-btn
										large
									
										color="teal white--text"

										@click="sms_verify"
										:loading="verify_sms_loading"
										>Verify</v-btn
									>
								</div>
							</div>
							
						</div>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		</div>

		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>


		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
	</v-snackbar>
	</div>

</template>

<script>
import parsePhoneNumber from 'libphonenumber-js'
import { signup_invite,verify_sms_invite,check_invite} from '@/api/user'
export default {

	name:"Signup",
	data() {
		return {
			check_inviate_loading:false,
			check_invite_message:"Checking inviatation code",
			verify_sms_loading:false,
			invite_code:"",
			valid_invite_code:false,
			signup_loading:false,
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			verify_object_id:null,
			reset_passwor:false,
			country_phone_code:['+973','+966','+965','+971','+968'],
			selected_country_phone_code:'+973',
			loading: false,
			name: "",
			name_rules:[
				v => !!v || 'Name is required',
				v => v.length<30 || 'Name is too long',
				v => v.length>3 || 'Name is too short',
				],

			phone: "",
			phone_rules:[
				v=> {
						// console.log(parsePhoneNumber()).isValid()
						const phoneNumber = parsePhoneNumber(this.selected_country_phone_code+ v)
						if (phoneNumber) {
							if (phoneNumber.isValid()){
								return true
							}
						//parseMobile(this.selected_country_phone_code+ v).isValid() || 'Invalid phone number'
					}  
					return 'Invalid phone number'
				}

				],
			email: "",
			email_rules:[
				v => !!v || 'Email is required',
				v => /.+@.+/.test(v) || 'Email must be valid',

			],
			password: "",
			password_rules:[
				v => v.length<160 || 'Password is too long',
				v => v.length>5 || 'Password is too short',
			],
			sms_code:'',
			sms_code_rules:[
				v => v.length ==5 || 'Invalid SMS Code',
			],

			hidePassword: true,
			error: false,
		
		};
	},

	methods: {
		signup() {
			//this.verify_object_id="something";

			if (this.$refs.signup_form.validate() ==false){

				this.snackbar_error_message = "Please fill required information";
				this.error_snackbar = true;
				return 
			}
			// do signup
			let signup_data={
				"name":this.name,
				"email":this.email,
				"password":this.password,
				"invite_code":this.invite_code,
				"phone":this.selected_country_phone_code+this.phone}
				this.signup_loading=true;
			signup_invite(signup_data).then(response => {
				console.log(response);
				if (response.error==true){
					this.snackbar_error_message = response.error_message;
					this.error_snackbar = true;
				}else{
					// show phone valiation form
					this.verify_object_id=response.data
				}
				this.signup_loading=false;

			})
				//vm.$router.push({ name: "Dashboard" });
		},
		sms_verify(){
			if (this.$refs.sms_verification_form.validate() ==false){
				this.snackbar_error_message = "Please enter valid sms code";
				this.error_snackbar = true;
				return 
			}
			let data_to_send={
				"object_id":this.verify_object_id,
				"sms_code":this.sms_code,
				"invite_code":this.invite_code
			};
			this.verify_sms_loading=true;
			verify_sms_invite(data_to_send).then(response => {
				console.log(response);
				this.verify_sms_loading=false;
				if (response.error==false){
					console.log("user data: ", response.data);
					const local_save={
						"session_key":response.data.session_key,
						"name":response.data.user.name,
						"email":response.data.user.email,
						"phone":response.data.user.phone,
						"status":response.data.user.status,
						};
					this.$store.commit('login', local_save);
					this.$store.commit('set_market', []);
					this.$router.push({ name: 'AddMarket' });

				}else{
					this.snackbar_error_message =response.error_message;
					this.error_snackbar = true;
				}
			});


		},
		cancel_validation(){
			this.verify_object_id=null;
		},
		check_invite_code(){
			this.check_inviate_loading=true;
			check_invite({"code":this.$route.params.code}).then(response => {
				console.log(response);
				this.check_inviate_loading=false;
				if (response.error==true){
					this.check_invite_message="Invalid invite link";
					this.valid_invite_code=false;
				}else{

					this.valid_invite_code=true;
				}
			});

		}
	},
	created:function(){
		this.invite_code=this.$route.params.code
		this.check_invite_code();
	}
};
</script>

<style scoped lang="css">
.signup-form{
	
}
</style>
