<template>
		<div class="reset-password-form">
			<v-toolbar flat>
			<v-btn
				icon
				@click="$router.go(-1)"
				>
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>Back</v-toolbar-title>
		</v-toolbar>
				<v-container fluid fill-height>
					<v-layout align-center justify-center>
						<v-flex xs12 sm8 md4 lg4>
							<v-card class="pa-4" outlined>
								<div v-if="forget_password_token ==null && reset_password_token==null">
									<v-card-text>
										<div class="layout column align-center">
											<img 
												src="@/assets/next-level-logo.svg" 
												alt="Next Level Login" 
												width="150" height="150">
											<h1 class=" flex my-8 font-weight-light">
												Reset Password
											</h1>
											<p>
												Please enter your signed-up phone number
											</p>
										</div>
										<v-form
										ref="phone_form">
												<div>
												<v-row>
													<v-col cols="4">
														<v-select
																:items="country_phone_code"
																label="Country Code"
																v-model="selected_country_phone_code"
																outlined
															></v-select>
													</v-col>
													<v-col cols="8">
														<v-text-field
															append-icon="mdi-phone"
															outlined
															name="phone"
															label="Phone"
															type="text"
															v-model="phone"
															:error="error"
															:rules="phone_rules"
													/>
												</v-col>
												
											</v-row>

											</div>
										</v-form>
										<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn 

											large 
											block 
											color="teal white--text" 
											@click="forget_password" 
											:loading="loading">Next</v-btn>

									</v-card-actions>
									</v-card-text>
									
									<p class="mt-4 font-weight-light body-2">
										If you have an account you  <router-link to="/login">Login here</router-link>
									</p>
									<p v-if="enable_signup"
									class=" font-weight-light body-2"
									>
									if you do not have an account you can <router-link to="/signup">Create a New Account</router-link> 
								</p>
								</div>

								<div v-if="forget_password_token !=null  && reset_password_token ==null">
									<v-card-text>
										<div class="layout column align-center">
											<img src="@/assets/next-level-logo.svg" alt="Next Level Login" width="150" height="150">
											<h1 class=" flex my-8 font-weight-light">
												Enter SMS code
											</h1>
											
										
										</div>
										<v-form
										ref="sms_code_form">
											
												<div>
												<v-row>
													<v-col>
													
													<v-text-field
														append-icon="mdi-message-text-outline"
														outlined
														name="sms_code"
														label="SMS Code"
														type="text"
														v-model="sms_code"
														:error="error"
														:rules="sms_code_rules"
													/>
												</v-col>
												
											</v-row>

											</div>
										</v-form>
									</v-card-text>
									<div class="px-4 font-weight-light">
										<div class="d-flex mb-6">
											<div>
												<v-btn
													large
												
													color="blue-grey white--text"

													@click="cancel_request"
													>Cancel</v-btn
												>
											</div>
											<div class="ml-auto">
												<v-btn
													large
													color="teal white--text"
													@click="send_sms_code"
													:loading="loading"
													>Validate</v-btn
												>
											</div>
										</div>
										
									</div>
									
								</div>

								<div v-if="reset_password_done==false">
									<div v-if="reset_password_token !=null && forget_password_token ==null ">
										<v-card-text>
											<div class="layout column align-center">
												<img src="@/assets/next-level-logo.svg" alt="Next Level Login" width="150" height="150">
												<h1 class=" flex my-8 font-weight-light">
													Enter new password
												</h1>
												
											
											</div>
											<v-form
											ref="reset_password_form"
											>
												
													<div>
													<v-row>
														<v-col>
														
														<v-text-field
																outlined
																:type="hidePassword ? 'password' : 'text'"
																:append-icon="
																	hidePassword
																		? 'mdi-eye-off-outline'
																		: 'mdi-eye-outline'
																"
																name="new_password"
																label="New Password"
																id="new_password"
																:rules="password_rules"
																v-model="new_password"
																:error="error"
																@click:append="hidePassword = !hidePassword"

															/>
													</v-col>
													
												</v-row>

												</div>
											</v-form>
										</v-card-text>
										<div class="px-4 font-weight-light">
											<div class="d-flex mb-6">
												<div>
													<v-btn
														large
													
														color="blue-grey white--text"

														@click="cancel_request"
														>Cancel</v-btn
													>
												</div>
												<div class="ml-auto">
													<v-btn

														large
														color="teal white--text"
														@click="reset_password"
														:loading="loading"
														>Set Password</v-btn
													>
												</div>
											</div>
											
										</div>
										
									</div>
								</div>
								<div v-if="reset_password_done==true">
									<v-card-text>
											<div class="layout column align-center">
												<img src="../../assets/next-level-logo.svg" alt="Next Level Login" width="150" height="150">
												<h1 class=" flex my-8 font-weight-light">
													Password changed
												</h1>
												
											
											</div>
										
												
													<div>
													<v-row>
														<v-col>
														<v-btn
														block
														large
														href="/login"
														color="teal white--text"
														>
															Login
														</v-btn>
														</v-col>
													
												</v-row>

												</div>
											
										</v-card-text>

								</div>


							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
			
			<v-snackbar
				v-model="showResult"
				:timeout="4000"
				top>
				{{ snack_message }}
			</v-snackbar>
		</div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js'
import { forget_password,forget_password_verify,reset_password} from '@/api/user'
export default {
	data() {
		return {
			reset_password_done:false,
			reset_password_token:null,
			forget_password_token:null,
			country_phone_code:['+973','+966','+965','+971','+968'],
			selected_country_phone_code:'+973',
			
			loading: false,
			phone: "",
			phone_rules:[
				v=> {
						// console.log(parsePhoneNumber()).isValid()
						const phoneNumber = parsePhoneNumber(this.selected_country_phone_code+ v)
						if (phoneNumber) {
							if (phoneNumber.isValid()){
								return true
							}
						//parseMobile(this.selected_country_phone_code+ v).isValid() || 'Invalid phone number'
					}  
					return 'Invalid phone number'
				}

				],
			new_password: "",
			password_rules:[
				v => v.length<160 || 'Password is too long',
				v => v.length>5 || 'Password is too short',
			],
			sms_code:'',
			sms_code_rules:[
				v => v.length==5 || 'Invalid code',
				],
			hidePassword: false,
			error: false,
			showResult: false,
			error_message:"",
			result: '',
			snack_message:'',
			
		}
	},

	methods: {
		forget_password(){
			const vm = this;
			if (this.$refs.phone_form.validate() ==false){
				console.log("Error in phone form");
				return 
			}
			// no problem
			let phone=this.selected_country_phone_code+this.phone;
			this.loading=true;
			forget_password({phone}).then(response => {
				this.loading=false;
				if (response.error==true){
					console.log("found error ", response.error_message);
					vm.showResult=true;
					vm.snack_message=response.error_message;
					return
				}else{
					//
					vm.forget_password_token=response.forget_password_token;
					vm.reset_password_token=null;
					console.log("forget_password_token: ",response.forget_password_token );
				}
			});

		},
		cancel_request(){
			this.reset_password_token=null;
			this.forget_password_token=null;
		},
		send_sms_code(){
			// validate 
			const vm = this;
			let data_to_send={"sms_code":this.sms_code,"forget_password_token":this.forget_password_token}
			this.loading=true;
			forget_password_verify(data_to_send).then(response=>{
				this.loading=false;
				if (response.error==true){
					console.log("found error ", response.error_message);
					vm.showResult=true;
					vm.snack_message=response.error_message;
					return
				}else{
					//
					vm.reset_password_token=response.reset_password_token;
					vm.forget_password_token=null;
					console.log("reset_password_token: ",response.reset_password_token );
				}
			});
		},
		reset_password() {
			const vm = this;
			let data_to_send={"new_password":this.new_password,"reset_password_token":this.reset_password_token}
			this.loading=true;
			reset_password(data_to_send).then(response=>{
				this.loading=false;
				if (response.error==true){
					console.log("found error ", response.error_message);
					vm.showResult=true;
					vm.snack_message=response.error_message;
					return
				}else{
					//
					vm.reset_password_done=true;
					console.log("R: ",response.message );
				}
			});

		}
	},
	computed: {
		enable_signup:function(){
			return process.env.VUE_APP_ENABLE_SIGNUP =='T'
		},
	}
}
</script>

<style scoped lang="css">
.reset-password-form{
	
}
</style>