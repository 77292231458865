<template>
	<v-container >
		
		<v-row><v-col md="6" cols="12">
		<v-card
			:disabled="loading_card"
			outlined
			class="pa-4"
			>
			<v-row>	
				<v-col >
						
						<v-form
							ref="update_name_form"
							>
							<v-card-title >Update Name:</v-card-title>
							<v-card-text>
								<v-text-field
									append-icon="mdi-account"
									outlined
									name="name"
									label="Name"
									type="text"
									v-model="new_name"
									:error="error"
									:rules="name_rules"
								/>
								<v-card-actions class="d-flex flex-row-reverse">
										<v-btn
											:loading="loading_name"
											color="teal white--text"
											@click="update_name"
										>
										Update
									</v-btn>

								</v-card-actions>
							</v-card-text>
						</v-form>
				
					</v-col>
				</v-row>
				
				<v-divider></v-divider>
				<v-row>	
				<v-col >
					<v-form	ref="update_phone_form">
						
						<v-card-title >Update Phone:</v-card-title>
							<v-card-text>
						
								<v-row>
									<v-col cols="4">
										<v-select
											:items="country_phone_code"
											label="Country Code"
											v-model="selected_country_phone_code"
											outlined
										></v-select>
									</v-col>
									<v-col cols="8">
										<v-text-field
											append-icon="mdi-phone"
											outlined
											name="phone"
											label="Phone"
											type="text"
											v-model="new_phone"
											:error="error"
											:rules="phone_rules"
										/>
									</v-col>
										
								</v-row>

				
								<v-card-actions class="d-flex flex-row-reverse">
									<v-btn
										:loading="loading_phone"
										color="teal white--text"
										@click="update_phone">
										Update
									</v-btn>
								</v-card-actions>
							</v-card-text>
						</v-form>
					</v-col>
				</v-row>
			<v-divider ></v-divider>
			<v-row>
			<v-col >
				<v-form	ref="update_email_form">
					
					<v-card-title >Update Email:</v-card-title>
					<v-card-text>
						<v-text-field
							append-icon="mdi-email"
							outlined
							name="login"
							label="Email"
							type="text"
							v-model="new_email"
							:error="error"
							:rules="email_rules"
							
						/>

								
						<v-card-actions class="d-flex flex-row-reverse">
							<v-btn 
								:loading="loading_email"
								color="teal white--text"
								@click="update_email">
								Update
							</v-btn>
						</v-card-actions>
					</v-card-text>
					</v-form>
				</v-col>
			</v-row>	
				<v-divider ></v-divider>
				<v-row>	
					<v-col>
						<v-form	ref="update_password_form">
							
							<v-card-title >Update Password:</v-card-title>
							<v-card-text>
								<v-text-field
											outlined
											:type="hide_current_password ? 'password' : 'text'"
											:append-icon="
												hide_current_password
													? 'mdi-eye-off-outline'
													: 'mdi-eye-outline'
											"
											name="password"
											label="Current Password"
											id="current_password"
											:rules="password_rules"
											v-model="current_password"
											:error="error"
											@click:append="hide_current_password = !hide_current_password"

										/>

								<v-text-field
											outlined
											:type="hide_new_password ? 'password' : 'text'"
											:append-icon="
												hide_new_password
													? 'mdi-eye-off-outline'
													: 'mdi-eye-outline'
											"
											name="password"
											label="New Password"
											id="new_password"
											:rules="password_rules"
											v-model="new_password"
											:error="error"
											@click:append="hide_new_password = !hide_new_password"

										/>
								<v-card-actions class="d-flex flex-row-reverse">
										<v-btn
											:loading="loading_password"
											color="teal white--text"
											@click="update_password"
										>
										Update
									</v-btn>

								</v-card-actions>
							</v-card-text>
						</v-form>
					</v-col>
			</v-row>
		</v-card>
	</v-col></v-row>

	
		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>

		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>

	</v-container>
</template>
<script>
import parsePhoneNumber from 'libphonenumber-js'
import { update_name,get_profile,update_phone,update_email,update_password} from '@/api/user'
import { mapState } from 'vuex'
export default {
	name: 'Profile',
	
	computed: mapState({
		name: state => state.user.name
	}),
	data(){
		return{
			loading_card:false,
			loading_name:false,
			loading_phone:false,
			loading_email:false,
			loading_password:false,
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			current_password:'',
			new_password:'',
			country_phone_code:['+973','+966','+965','+971','+968'],
			selected_country_phone_code:'',
			error:false,
			edit_name:true,
			new_name:'',
			name_rules:[
				v => !!v || 'Name is required',
				v => v.trim().length<30 || 'Name is too long',
				v => v.trim().length>2 || 'Name is too short',
				],
			new_phone:'',
			phone_rules:[
				v=> {
						// console.log(parsePhoneNumber()).isValid()
						const phoneNumber = parsePhoneNumber(this.selected_country_phone_code+ v.trim())
						if (phoneNumber) {
							if (phoneNumber.isValid()){
								return true
							}
						//parseMobile(this.selected_country_phone_code+ v).isValid() || 'Invalid phone number'
					}  
					return 'Invalid phone number'
				}

				],

			new_email: "",
			email_rules:[
				v => !!v.trim() || 'Email is required',
				v => /.+@.+/.test(v.trim()) || 'Email must be valid',

			],
			password: "",
			password_rules:[
				v => v.length<160 || 'Password is too long',
				v => v.length>5 || 'Password is too short',
			],
		
			hide_current_password:true,
			hide_new_password:true,
		}
	},
	mounted() {
		// load profile data from server

		// this.new_name=this.$store.getters.name;
		// this.new_email=this.$store.getters.email;
		// this.new_phone=this.$store.getters.phone.substr(4);
		// this.selected_country_phone_code=this.$store.getters.phone.substr(0,4)
		this.load_pofile()
	},
	methods:{
		load_pofile(){
			this.loading_card=true;
			get_profile().then(response => {
				console.log("response: ",response);
				if (response.error==false){
					this.loading_card=false;
					this.new_name=response.data.name
					this.new_email=response.data.email
					this.new_phone=response.data.phone.substr(4)
					this.selected_country_phone_code=response.data.phone.substr(0,4)
					// update load value?

				}

			})
		},
		update_password(){

			console.log("update password");
			//update_password_form
			if (this.$refs.update_password_form.validate()==false){
				console.log("error in password");
				this.error_snackbar=true;
				this.snackbar_error_message="invalid password value";
				return
			}
			let data_to_send={
				"current_password":this.current_password,
				"new_password":this.new_password
				};
			this.loading_password=true;
			update_password(data_to_send).then(response => {
				this.loading_password=false;
				console.log("response: ",response);
				if (response.error==true){
					this.error_snackbar=true
					this.snackbar_error_message='Password error: '+response.error_message;
				}else{
					this.success_snakbar=true;
					this.snackbar_success_message='Password updated';
				}

			})
		},
		update_email(){
			
			if (this.$refs.update_email_form.validate()==false){
				console.log("error in email number");
				this.error_snackbar=true;
				this.snackbar_error_message="invalid email";
				return
			}
			let data_to_send={"new_email":this.new_email};
			this.loading_email=true;
			update_email(data_to_send).then(response => {
				this.loading_email=false;
				console.log("response: ",response)
				if (response.error==true){
					this.error_snackbar=true
					this.snackbar_error_message='Email error: '+response.error_message;
				}else{
					this.success_snakbar=true;
					this.snackbar_success_message='Email updated';
				}
			});
			

		},
		update_phone(){
			//validate phone
			console.log("update phone");
			if (isNaN(this.new_phone)==true){
				console.log("phone is not a number");
				this.error_snackbar=true;
				this.snackbar_error_message="invalid phone number";
				return
			}
			console.log("phone validation result: ",this.$refs.update_phone_form.validate());
			if (this.$refs.update_phone_form.validate()==false){
				console.log("error in phone number");
				this.error_snackbar=true;
				this.snackbar_error_message="invalid phone number";
				return
			}
			// update phone number
			let data_to_send={"new_phone":this.selected_country_phone_code+this.new_phone};
			this.loading_phone=true;
			console.log("data_to_send: ",data_to_send);
			update_phone(data_to_send).then(response => {
				this.loading_phone=false;
				console.log("response: ",response)
				if (response.error==true){
					this.error_snackbar=true
					this.snackbar_error_message='Phone error: '+response.error_message;
				}else{
					this.success_snakbar=true;
					this.snackbar_success_message='Phone number updated';
				}
			});

		},
		update_name(){
			if (this.$refs.update_name_form.validate() ==false){
				console.log("update user name error")
				return 
			}
		
			// update name
			let new_name=this.new_name.trim();
			this.loading_name=true;
			update_name({new_name}).then(response => {
				this.loading_name=false;
				console.log("Response",response);
				if (response.error==true){
					this.error_snackbar=true
					this.snackbar_error_message='Name error: '+response.error_message;
				}else{
					this.success_snakbar=true;
					this.snackbar_success_message='Name updated';
				}
			})

		},

	}


}

</script>

<style scoped>

</style>